/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Title, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Text, Image, Subtitle, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu pb--08 pl--20 pr--25 pt--08" menu={true} name={"menu"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <Menu className="--left pb--08 pl--0 pr--0 pt--08" fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box" style={{"maxWidth":388}}>
              
              <Title className="title-box ff--1 fs--30 w--500" content={"RM Elektroinstalace s.r.o."}>
              </Title>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--right" style={{"marginTop":0,"paddingTop":0,"marginBottom":0}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 fs--20 w--400 pr--10" href={"#onas"} tsbtn={null} target={""} content={"<span style=\"font-weight: bold;\">O nás</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--20 w--400 pr--10" href={"#sluzby"} target={""} content={"<span style=\"font-weight: bold;\">Služby</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 fs--20 w--400 pr--10" href={"#reference"} target={""} content={"<span style=\"font-weight: bold;\">Reference</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--20 w--400 pr--10" href={"#paticka"} target={""} content={"<span style=\"font-weight: 700;\">Kontakt</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--cColor1 btn-box--shadow5 fs--20 w--500" href={"#kontakt"} target={""} content={"Poptávkový formulář"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--40 pr--25 pt--40" name={"8sfnv5uvpne"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box w--500 pl--10" style={{"maxWidth":802}} content={"Elektroinstalační práce v komerčních <br>i soukromých objektech"}>
              </Title>

              <Text className="text-box fs--24 w--500 pl--16 pt--20" content={"<span style=\"font-style: italic;\">Práci provádíme tak, jako bychom ji dělali na vlastních objektech</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" name={"onas"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image className="mt--0 pl--20 pt--0" src={"https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_s=660x_.JPG"} svg={false} ratio={"3:2"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_s=350x_.JPG 350w, https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_s=660x_.JPG 660w, https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_s=860x_.JPG 860w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left fs--20" style={{"maxWidth":334}} content={"<span style=\"color: rgb(0, 0, 0);\">Náš příběh se datuje k roku 1997, kdy se náš otec, Roman Lesonický, pracující v oblasti elektroinstalací, rozhodl opustit zaměstnání a stát se osobou samostatně výdělečně činnou. Nebyl to jednoduchý krok, ale čas ukázal, že to byla cesta správným směrem. Od rodinných domů se postupně propracoval k realizaci milionových zakázek. Já, Mgr. Roman Lesonický, společně s bratrem, Ing. Michalem Lesonickým, jsme se podíleli na realizaci většiny zakázek, kde jsme se učili dané řemeslo a věci s nimi spojené. Zároveň jsme se však vzdělávali v ekonomii, managementu, financích či řízení podniku při studiu na vysoké škole. Rok 2020 přinesl spoustu nečekaných změn, ale my se rozhodli posunout rodinný business na další úroveň, kterou je založení firmy RM Elektroinstalace.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left fs--20" style={{"maxWidth":334}} content={"<span style=\"color: rgb(0, 0, 0);\">Čím se vyznačujeme a jaké jsou naše charakteristické znaky, které stojí za růstem rodinné firmy? Jednoznačně rychlost a kvalita provedené práce. Zakázky realizujeme tak, jako bychom pracovali na svém domě a vždy se snažíme vyhovět přáním a potřebám zákazníka. Zakázku od začátku až do konce pravidelně konzultujeme se zadavatelem tak, aby byl výsledek přesně takový, jaký si náš zákazník přeje. I když nastane nějaký problém nebo změna, což se většinou stane, vždy najdeme rychlé řešení a návrh poupravíme. V těchto nastavených standardech hodláme pokračovat a těšíme se na další spolupráce.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40" name={"sluzby"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --center el--3 pb--0 pt--0 flex--center" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center pb--30" style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-1.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box w--300 ls--002" content={"Výstavba"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Provádíme elektroinstalační práce v novostavbách i v nově vznikajících průmyslových objektech&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--50" style={{"maxWidth":300}}>
              
              <Image className="pb--0" src={"https://cdn.swbpg.com/o/g/Ikonky/Auto-Moto/auto-moto-16.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Rekonstrukce"}>
              </Title>

              <Text className="text-box fs--20" content={"Zabýváme se také rekonstrukcemi soukromých i komerčních objektů&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--60" style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Cenik/cenik-6.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Doplňkové práce"}>
              </Title>

              <Text className="text-box fs--20 pb--10 pt--0" content={"&nbsp;Revize, servis a další specifické práce&nbsp;&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--08 pb--30" style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/88e67c255f7d45fbadefead760a44b9f.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box w--300 ls--002" content={"Chytré instalace"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--30 pt--0" style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-26.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box w--300 ls--002 pt--0" content={"Podlahové topení"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--16 pb--20" style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/1bf57d65067e4fcd82d8e6c77685617b.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box w--300 ls--002" content={"Hromosvody"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--16 pb--20" style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/9be7e5326e8f479cae39ec6d6609aa04.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box w--300 ls--002" content={"EZS"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--16 pb--20" style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/mobil-2.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box w--300 ls--002" content={"EPS"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"nadpis-reference"} style={{"paddingTop":null,"backgroundColor":"var(--color-variable-1--darken)"}}>
        </Column>


        <Column className="bg--bottom pb--60 pt--40" name={"xnqnkt9hcfg"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" anim={null} style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style3 pb--30" content={"<span style=\"color: rgb(240, 252, 81);\">Proč si vybrat právě nás?</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--24 w--400" content={"Vysoce kvalitní zpracování"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Kvalitou provedení zakázky budujeme své jméno již řadu let a nehodláme na tom nic měnit i nadále"}>
              </Text>

              <Subtitle className="subtitle-box fs--24 w--400" content={"Rychlost provedení"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Byť je u nás kvalita na prvním místě, tak neztrácíme na rychlosti"}>
              </Text>

              <Subtitle className="subtitle-box fs--24 w--400" content={"Dostupná cena"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Máme ověřeno zakázkami, že naše ceny jsou více než přívětivé"}>
              </Text>

              <Subtitle className="subtitle-box fs--24 w--400" content={"Rozumná domluva"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Lidský přístup, rychlá reakce na změny a přání zákazníka"}>
              </Text>

              <Subtitle className="subtitle-box fs--24 w--400" content={"Pohotovost 24/7"}>
              </Subtitle>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Jsme tu pro vás neustále na telefonu či emailu"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"reference"} style={{"backgroundColor":"rgba(232, 239, 234, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3 mt--0 flex--top" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/11759/e257c6e9dfd0424ba8397e80538bb9e3_s=860x_.jpg"} svg={false} href={"/rekonstrukce-banky-ve-zline"} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11759/e257c6e9dfd0424ba8397e80538bb9e3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/e257c6e9dfd0424ba8397e80538bb9e3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/e257c6e9dfd0424ba8397e80538bb9e3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/e257c6e9dfd0424ba8397e80538bb9e3_s=1400x_.jpg 1400w"} target={null} position={{"x":"-0.5698005698005698%","y":"-44.3931623931624%"}}>
              </Image>

              <Title className="title-box mt--06" content={"<a href=\"/rekonstrukce-banky-ve-zline\">Rekonstrukce banky ve Zlíně&nbsp;</a><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=860x_.jpg"} svg={false} href={"https://www.rmelektroinstalace.cz/hromosvod"} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/da8af83335b04d45b442713f31a1fee5_s=2000x_.jpg 2000w"} target={null} position={{"x":"-1.4450867052023046%","y":"-4.391304347826081%"}}>
              </Image>

              <Title className="title-box mt--06" content={"<a href=\"https://www.rmelektroinstalace.cz/hromosvod\">Instalace hromosvodu na&nbsp;</a>RD"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=860x_.JPG"} svg={false} href={"https://www.rmelektroinstalace.cz/elektroinstalace-vyrobni-hala"} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=350x_.JPG 350w, https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=660x_.JPG 660w, https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=860x_.JPG 860w, https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=2000x_.JPG 2000w"} target={null}>
              </Image>

              <Title className="title-box mt--06" content={"<a href=\"https://www.rmelektroinstalace.cz/elektroinstalace-vyrobni-hala\">Elektroinstalační práce ve výrobní hale</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=860x_.jpg"} svg={false} href={"https://www.rmelektroinstalace.cz/elektroinstalace-v-bance"} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=2000x_.jpg 2000w"} target={null}>
              </Image>

              <Title className="title-box mt--06" content={"<a href=\"https://www.rmelektroinstalace.cz/elektroinstalace-v-bance\">Elektroinstalační práce v bance</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/11759/382cc614cee14cfb83fe2d99c81a731e_s=860x_.jpg"} svg={false} href={"https://www.rmelektroinstalace.cz/kompletace-osvetleni"} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11759/382cc614cee14cfb83fe2d99c81a731e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/382cc614cee14cfb83fe2d99c81a731e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/382cc614cee14cfb83fe2d99c81a731e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/382cc614cee14cfb83fe2d99c81a731e_s=1400x_.jpg 1400w"} target={null}>
              </Image>

              <Title className="title-box mt--06" content={"<a href=\"https://www.rmelektroinstalace.cz/kompletace-osvetleni\">Kompletace osvětlení v RD</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=860x_.jpg"} svg={false} href={"https://www.rmelektroinstalace.cz/osvetleni-vyrobni-hala"} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=2000x_.jpg 2000w"} target={null}>
              </Image>

              <Title className="title-box mt--06" content={"<a href=\"https://www.rmelektroinstalace.cz/osvetleni-vyrobni-hala\">Výměna osvětlení ve výrobní hale</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/11759/558e1c288c5b4b54bcc075c71a341ee1_s=860x_.jpg"} svg={false} href={"/silnoprouda-instalace-v-bance-v-olomouci"} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11759/558e1c288c5b4b54bcc075c71a341ee1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/558e1c288c5b4b54bcc075c71a341ee1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/558e1c288c5b4b54bcc075c71a341ee1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/558e1c288c5b4b54bcc075c71a341ee1_s=1400x_.jpg 1400w"} target={null}>
              </Image>

              <Title className="title-box mt--06" content={"<a href=\"https://www.rmelektroinstalace.cz/silnoprouda-instalace-v-bance-v-Olomouci\">Elektroinstalace banka Olomouc</a><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-o84391 --left bg--center pb--80 pt--40" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_bl=3_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_bl=3_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_bl=3_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_bl=3_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_bl=3_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_bl=3_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_bl=3_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/11759/7205e6d1a876454d8ac5864c247f7132_bl=3_s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--25 pt--0" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--48 w--400 title-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">Ozveme se Vám</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <ContactForm className="--shape2 --shape2 --style3 fs--18" style={{"maxWidth":600}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Kontaktní e-mail","type":"email","placeholder":"Zadejte Váš e-mail"},{"name":"Co pro Vás můžeme udělat?","type":"textarea"},{"name":"Kontaktovat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--0 pt--0" name={"sptdwc8vyqo"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex el--4 mb--60 mt--60" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="pr--0" style={{"maxWidth":310}}>
              
              <Title className="title-box" content={"Kontakt"} maxWidth={"240"}>
              </Title>

              <Text className="text-box fs--18 lh--16 pl--0 pr--0" style={{"maxWidth":334}} content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">Mgr. Roman Lesonický<br></span><span style=\"color: rgb(0, 0, 0); font-family: &quot;Public Sans&quot;, sans-serif; font-size: 18px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal;\">Jednatel společnosti</span><span style=\"color: rgb(0, 0, 0);\"><br>737 196 331<br>obchod@rmelektroinstalace.cz<br><br><span style=\"font-weight: bold;\">Ing. Michal Lesonický<br></span></span><span style=\"font-size: 18px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-family: &quot;Public Sans&quot;, sans-serif; color: rgb(0, 0, 0);\">Jednatel společnosti</span><br style=\"color: rgb(0, 0, 0);\"><span style=\"color: rgb(0, 0, 0);\">736 226 167<br>obchod@rmelektroinstalace.cz<br><br><span style=\"font-weight: bold;\">Roman Lesonický<br></span>Technický specialista silnoproud<br>604 365 351<br>obchod@rmelektroinstalace.cz<br><br><span style=\"font-weight: bold;\">Zdeněk Burda</span><br>Technický specialista slaboproud<br>737 964 545<br>obchod@rmelektroinstalace.cz<br></span>"} maxWidth={"240"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--0" style={{"maxWidth":240}}>
              
              <Title className="title-box" content={"Společnost"} maxWidth={"180"}>
              </Title>

              <Text className="text-box fs--18 lh--16" content={"<span style=\"color: rgb(0, 0, 0);\"><span style=\"font-weight: bold;\">RM Elektroinstalace s.r.o.<br></span>Nížkovice 286<br>684 01 Nížkovice<br>Česká republika<br>IČ: 09365621<br>DIČ: CZ09365621<br></span>"} maxWidth={"240"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":220}}>
              
              <Title className="title-box" content={"Sociální sítě"}>
              </Title>

              <Text className="text-box fs--22 lh--14" style={{"color":"#000;","maxWidth":270}} content={"<span style=\"text-decoration-line: underline;\"><a href=\"https://www.facebook.com/RMElektroinstalace/\" target=\"_blank\">facebook</a><br></span>"}>
              </Text>

              <Text className="text-box fs--22 lh--14 mt--0" style={{"color":"#000;"}} content={"<span style=\"text-decoration-line: underline;\"><a href=\"https://www.instagram.com/rm_elektroinstalace/\" target=\"_blank\">instagram</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Navigace"} maxWidth={"240"}>
              </Title>

              <Text className="text-box fs--18 lh--16" content={"<a href='#menu'>Zpět nahoru</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--18 lh--16 mt--0" content={"<a href='#sluzby'>Služby</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--18 lh--16 mt--0" content={"<a href='#reference'>Reference</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--18 lh--16 mt--0" content={"<a href='#informace'>Informace</a>"} maxWidth={"240"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}